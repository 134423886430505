import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Wrap from "components/Wrap";

export const WrapStyled = styled(Wrap)({
  padding: "28px 0 24px",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  zIndex: 1,

  [media("tablet")]: {
    minHeight: "100vh",
    padding: "70px 0",
  },
});
