import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import Text from "components/Text";
import TextVisual from "components/TextVisual";
import Headline from "components/Headline";
import ImageStripe from "components/ImageStripe";

export const TextStyled = styled("div")({
  whiteSpace: "pre-line",
});

export const TextBelowImageStyled = styled(Text)({
  whiteSpace: "pre-line",
});

export const ImageStripeStyled = styled(ImageStripe)({}, (props) => ({
  borderRadius: props.imageRounded ? "50%" : null,
}));

export const TextVisualStyled = styled(TextVisual)({
  top: 220,
  width: 0,
  fontSize: 0,
  zIndex: 1,
  [media("tabletFluid")]: {
    top: 0,
    width: "1.2em",
    fontSize: "13vw",
  },
  [media("laptop")]: {
    fontSize: "9vw",
  },
});

export const HeadlineStyled = styled(Headline)({
  fontSize: 26,
  margin: "70px 0 20px 0",
  [media("tabletFluid")]: {
    fontSize: 40,
    margin: "80px 0 30px 0",
  },
});
