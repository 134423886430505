import React from 'react'
import { parseAsText } from 'utils/parser'

import Button from 'components/Button'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import Redactor from 'components/Redactor'
import Fade from 'react-reveal/Fade'

import {
  TextStyled,
  GridItemImageRightStyled,
  ImageStripeStyled,
  TextVisualStyled,
  TextBelowImageStyled,
  HeadlineStyled,
} from './ImageTextRightStyled'

export default function ImageTextRight({ pageBuilder }) {
  const {
    headline,
    titleFont,
    textSectionText,
    textvisual,
    image,
    button,
    imageRounded,
    textBelowImage,
    useImageProportions,
  } = pageBuilder

  const textContent = textSectionText?.content
  const textBelowImageContent = textBelowImage?.content
  const ratio = useImageProportions
    ? image[0].width / image[0].height
    : imageRounded
    ? 1 / 1
    : 9 / 16

  return (
    <Grid>
      <GridItem mobile={1} tabletFluid={useImageProportions ? 4 / 8 : 3 / 8}>
        {image[0] && (
          <Fade bottom distance="10px" delay={200}>
            <ImageStripeStyled
              args={{ maxWidth: 1000 }}
              fluid={image[0]}
              aspectRatio={ratio}
              hasBoxShadow
              imageRounded={imageRounded}
              hasStripe
              stripePosition="left"
            />
          </Fade>
        )}
        {textBelowImageContent && (
          <Fade bottom distance="10px" delay={400}>
            <TextBelowImageStyled
              textAlign={imageRounded ? 'center' : null}
              margin="20px 0 50px 0"
              element="p">
              <Redactor content={textBelowImageContent} parseType="content" />
            </TextBelowImageStyled>
          </Fade>
        )}
      </GridItem>
      <GridItem mobile={1} tabletFluid={useImageProportions ? 4 / 8 : 5 / 8}>
        <Grid height="auto">
          <GridItem>
            {textvisual && (
              <TextVisualStyled useImageProportions>
                {parseAsText(textvisual)}
              </TextVisualStyled>
            )}
          </GridItem>
        </Grid>
        <Grid>
          <GridItem
            mobile={1}
            tabletFluid={0 / 5}
            laptop={useImageProportions ? 0 : 1 / 5}
          />
          <GridItem mobile={1} tabletFluid={5 / 5} laptop={4 / 5}>
            <Grid>
              <GridItemImageRightStyled>
                {headline && (
                  <Fade bottom distance="10px" delay={600}>
                    <HeadlineStyled element="h2" titleFont={titleFont}>
                      {parseAsText(headline)}
                    </HeadlineStyled>
                  </Fade>
                )}
                {textContent && (
                  <Fade bottom distance="10px" delay={800}>
                    <TextStyled margin="0 0 50px 0" element="p">
                      <Redactor content={textContent} parseType="content" />
                    </TextStyled>
                  </Fade>
                )}

                {button.url && (
                  <Fade left distance="10px" delay={1000}>
                    <Button
                      to={button?.entry?.fullUri || button?.url}
                      target={button.target}
                      icon="arrowRight"
                      themeName="green"
                      uppercase>
                      {button.text}
                    </Button>
                  </Fade>
                )}
              </GridItemImageRightStyled>
            </Grid>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
