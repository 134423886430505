import React from 'react'

import { parseAsText } from 'utils/parser'

import Button from 'components/Button'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import Redactor from 'components/Redactor'
import Fade from 'react-reveal/Fade'

import {
  TextStyled,
  TextVisualStyled,
  TextBelowImageStyled,
  ImageStripeStyled,
  HeadlineStyled,
} from './ImageTextLeftStyled'

export default function ImageTextLeft({ pageBuilder }) {
  const {
    headline,
    textSectionText,
    textvisual,
    image,
    button,
    imageRounded,
    textBelowImage,
    largerImage,
    titleFont,
    useImageProportions,
  } = pageBuilder

  const textContent = textSectionText?.content

  const textBelowImageContent = textBelowImage?.content

  const ratio = useImageProportions
    ? image[0].width / image[0].height
    : imageRounded
    ? 1 / 1
    : 16 / 9

  return (
    <Grid>
      <GridItem mobile={1} tabletFluid={largerImage ? 1 / 10 : 2 / 5}>
        <TextVisualStyled>{parseAsText(textvisual)}</TextVisualStyled>
      </GridItem>
      <GridItem mobile={1} tabletFluid={largerImage ? 9 / 10 : 3 / 5}>
        <Grid>
          <GridItem>
            {image[0] && (
              <Fade bottom distance="10px" delay={200}>
                <ImageStripeStyled
                  fluid={image[0]}
                  aspectRatio={ratio}
                  hasBoxShadow
                  imageRounded={imageRounded}
                  hasStripe
                  stripePosition="right"
                />{' '}
              </Fade>
            )}
            {textBelowImageContent && (
              <Fade bottom distance="10px" delay={200}>
                <TextBelowImageStyled
                  textAlign={imageRounded ? 'center' : null}
                  margin="20px 0 50px 0"
                  element="p">
                  <Redactor
                    content={textBelowImageContent}
                    parseType="content"
                  />
                </TextBelowImageStyled>
              </Fade>
            )}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem mobile={1} tabletFluid={3 / 12} />
      <GridItem mobile={1} tabletFluid={9 / 12}>
        <Grid>
          <GridItem zIndex="2">
            {headline && (
              <Fade bottom distance="10px" delay={400}>
                <HeadlineStyled element="h2" titleFont={titleFont}>
                  {parseAsText(headline)}
                </HeadlineStyled>
              </Fade>
            )}
            {textContent && (
              <Fade bottom distance="10px" delay={600}>
                <TextStyled margin="0 0 50px 0">
                  <Redactor content={textContent} parseType="content" />
                </TextStyled>
              </Fade>
            )}
            {button.url && (
              <Fade left distance="10px" delay={800}>
                <Button
                  to={button?.entry?.fullUri || button?.url}
                  target={button.target}
                  icon="arrowRight"
                  themeName="green"
                  uppercase>
                  {button.text}
                </Button>
              </Fade>
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
