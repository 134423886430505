import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import Text from "components/Text";
import GridItem from "components/GridItem";
import ImageStripe from "components/ImageStripe";
import Headline from "components/Headline";
import TextVisual from "components/TextVisual";

export const ImageStripeStyled = styled(ImageStripe)({}, (props) => ({
  borderRadius: props.imageRounded ? "50%" : null,
}));

export const TextVisualStyled = styled(TextVisual)(
  {
    top: 40,
    width: 0,
    zIndex: 1,

    [media("tabletFluid")]: {
      top: 0,
      width: "1.2em",
    },
  },
  (props) => ({
    fontSize: 0,

    [media("tabletFluid")]: {
      fontSize: props.useImageProportions ? "6vw" : "10vw",
    },
    [media("laptop")]: {
      fontSize: props.useImageProportions ? "6vw" : "10vw",
    },
  })
);

export const TextBelowImageStyled = styled(Text)({
  whiteSpace: "pre-line",
});

export const TextStyled = styled("div")({
  whiteSpace: "pre-line",
});

export const GridItemImageRightStyled = styled(GridItem)({
  alignSelf: "flex-end",
  zIndex: 2,
});

export const HeadlineStyled = styled(Headline)({
  fontSize: 26,
  margin: "70px 0 20px 0",
  [media("tabletFluid")]: {
    fontSize: 40,
    margin: "50px 0 30px 0",
  },
});
