import React from 'react';
import { graphql } from 'gatsby';

import Overlay from 'components/Overlay';
import SwitchTheme from 'components/SwitchTheme';

import ImageTextLeft from './ImageTextLeft';
import ImageTextRight from './ImageTextRight';

import { WrapStyled } from './ImageTextStyled';

export default function ImageText({ pageBuilder }) {
  const { layout, imageTextTheme } = pageBuilder;

  const imageTextType = {
    // imageTextRight: ImageTextLeft,
    // imageTextLeft: ImageTextRight,
    imageTextLeft: ImageTextLeft,
    imageTextRight: ImageTextRight,
  };

  const ImageTextComponent = imageTextType[layout];

  return (
    <SwitchTheme name={imageTextTheme}>
      <WrapStyled>
        <Overlay background="sectionImageTextBackground" />
        <ImageTextComponent pageBuilder={pageBuilder} />
      </WrapStyled>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment PageBuilderImageTextQuery on Craft_PageBuilderImageText {
    headline
    titleFont
    textBelowImage {
      content
    }
    imageRounded
    useImageProportions
    textSectionText {
      content
    }
    textvisual
    image {
      ...CraftImageFluid
    }
    layout
    imageTextTheme
    largerImage
    button {
      target
      text
      url
      entry {
        title
        fullUri
      }
    }
  }
`;
